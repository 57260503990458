import { $qs, $qsa } from './utils/QuerySelector.js';
import { media } from './utils/MediaQueries.js';
import Lottie from './animations/Lottie';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from 'gsap/SplitText';
import CustomEase from 'gsap/CustomEase';
import Swiper from 'swiper';
import { Navigation, EffectFade } from 'swiper/modules';
import 'magnific-popup';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
gsap.registerPlugin(CustomEase);



function loadingAnimation() {
    const bgLines = $qsa('[data-target="bg-lines"] > div');

    const timeline = gsap.timeline();

    const hackleyLogo = $qs('[data-name="hackley-logo"]');
    const logoX = $qs('[data-name="logo-x"]');
    const bannerLottie = $qs('[data-name="banner-lottie"]');

    gsap.to([hackleyLogo, logoX], {autoAlpha: 1, duration: 2});
    
    window.addEventListener('load', () => {
        gsap.set(bannerLottie, {opacity: 1});
    });

    timeline
        .to(bgLines, {
            y: '0%',
            duration: 10,
            ease: 'power3.inOut',
            // stagger: 0.2,
        });
}

function scrollText() {
    const scrollText = $qs('[data-target="scroll-text"]');
    const banner = $qs('[data-name="banner"]');

    gsap.to(scrollText, {
        opacity: 0,
        ease: 'none',
        scrollTrigger: {
            trigger: banner,
            scrub: true,
            start: 'top top',
            end: 'bottom 50%',
        }
    })

    gsap.to('[data-target="scroll-text"] span.main', {
        repeat: -1,
        repeatDelay:1.5,
        keyframes: [
            { translateY: 0, duration: 0 },
            { translateY: -25, duration: 0.5,ease: CustomEase.create("custom", "M0,0 C0.07,-0.4 0.69,1.39 1,1 "), },
        ],
    });
      
    gsap.to('[data-target="scroll-text"] span.second', {
        repeat: -1,
        repeatDelay:1.5,
        keyframes: [
            { translateY: 25, duration: 0 },
            { translateY: 0, duration: 0.5,ease: CustomEase.create("custom", "M0,0 C0.07,-0.4 0.69,1.39 1,1 "), },
        ],
    });
}

function textReveal() {
    const textReveals = $qsa('[data-target="text-reveal"]');
    console.info('text-reveal');
    textReveals.forEach(text => {

    
    const paragraphs = $qsa('p', text);
    const splitText = new SplitText(paragraphs, { type: 'chars,words' });

    gsap.fromTo(text, {
        transformOrigin: '0% 50%',
        rotate: 3
    }, {
        ease: 'none',
        rotate: 0,
        scrollTrigger: {
            trigger: text,
            start: 'top bottom',
            end: '50% bottom',
            scrub: true,
        }
    });

    gsap.fromTo(splitText.chars, {
        'will-change': 'opacity',
        opacity: 0.15,
    }, {
        ease: 'none',
        opacity: 1,
        stagger: 0.05,
        scrollTrigger: {
            trigger: text,
            start: 'top bottom-=20%',
            end: 'center top+=20%',
            scrub: true,
            // markers: true,
        }
    });
})
}

function videoPopup() {
    $('[data-target="magnific-video"], .magnific-video').magnificPopup({
        type: 'iframe',
        mainClass: 'mfp-video-wrapper',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
        iframe: {
            markup: '<div class="mfp-iframe-scaler">' +
                '<div class="mfp-close"></div>' +
                '<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
                '</div>',
            patterns: {
                youtu: {
                    index: 'youtu.be',
                    id: function( url ) {
                    
                        // Capture everything after the hostname, excluding possible querystrings.
                        var m = url.match( /^.+youtu.be\/([^?]+)/ );
                
                        if ( null !== m ) {
                            return m[1];
                        }
                
                        return null;
            
                    },
                    // Use the captured video ID in an embed URL. 
                    // Add/remove querystrings as desired.
                    src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
                },
                youtube: {
                    index: 'youtube.com/',
                    id: 'v=',
                    src: 'https://www.youtube.com/embed/%id%?autoplay=1'
                },
                vimeo: {
                    index: 'vimeo.com/', 
                    id: function(url) {        
                        var m = url.match(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
                        if ( !m || !m[5] ) return null;
                        return m[5];
                    },
                    src: 'https://player.vimeo.com/video/%id%?autoplay=1'
                }
            }
        },
        closeMarkup: `<button title="%title%" type="button" class="mfp-close !top-0 !right-0 !p-0 !-translate-y-1/2 !translate-x-1/2"><svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="30" cy="30" r="30" fill="#E0F9FD" fill-opacity="0.95"/><line x1="21.3536" y1="21.6171" x2="39.3536" y2="39.6171" stroke="#0C2957"/><line x1="39.3829" y1="21.3536" x2="21.3829" y2="39.3536" stroke="#0C2957"/></svg></button>`
    });
}

function principlesSlider() {
    const principles = $qs('[data-name="principles"]');
    const sliderElement = $qs('[data-name="principles-slider"]');
    const switches = $qsa('[data-name="switch-principle-slides"]');



    const swiper = new Swiper(sliderElement, {
        modules: [ Navigation, EffectFade ],
        // slidesPerView: 1,
        autoHeight: true,
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        // navigation: {
        //     nextEl: '.swiper-button-next',
        //     prevEl: '.swiper-button-prev',
        // },
        on: {
            slideChange: function(){
                console.log('slide change')
                let sliderCounterParent = $qs('[data-name="principle-count"]');
                console.info(sliderCounterParent);
                let { countStarted } = sliderCounterParent.dataset;
                let currentSlide = this.previousRealIndex;
                let currentCountNumber = $qs(`[data-count="${currentSlide}"]`);
                let nextCountNumber = $qs(`[data-count="${this.realIndex}"]`);

                if (currentSlide == this.realIndex) return;
                const currentControl = $qs('[data-name="switch-principle-slides"][data-value="' + currentSlide + '"]');
                const currentControlBox = $qs('div:first-child', currentControl);
                gsap.to(currentControl, {fontWeight: 300, textDecoration: 'none'});
                gsap.to(currentControlBox, {background: 'white', borderColor: 'black'});
                const control = $qs('[data-name="switch-principle-slides"][data-value="' + this.realIndex + '"]');
                const controlBox = $qs('div:first-child', control);
                gsap.to(control, {fontWeight: 400, textDecoration: 'underline'});
                gsap.to(controlBox, {background: '#E0F9FD', borderColor: '#E0F9FD'});
                const timeline = gsap.timeline({});

                timeline
                    .addLabel('start')
                    .to(currentCountNumber, {
                        y: '-100%',
                        duration: 0.3,
                        ease: 'power3.inOut'
                    }, 'start')
                    .to(nextCountNumber, {
                        y: '0%',
                        duration: 0.3,
                        ease: 'power3.inOut'
                    }, 'start');

                    timeline.set(currentCountNumber, {
                        y: '100%',
                    });
            },
        },
    });

if(window.innerWidth > 824){
    let pinTL = new gsap.timeline({
        scrollTrigger: {
            trigger: principles,
            pin: principles,
            pinSpacing: true,
            start: 'top top',
            end: '200%',
            scrub: true,
            markers: false
        },
      });
    

      switches.forEach(item => {
        pinTL.add(() => { console.info('principle scroll change'); swiper.slideTo(item.dataset.value) });
        pinTL.to(switches, {opacity: 1});
    })
}

    switches.forEach(item => {
        item.addEventListener('click', () => { 
            console.info('change slide click'); 
swiper.slideTo(item.dataset.value)
        })
    })
}

function testimonialsSlider() {
    const sliderElement = $qs('[data-target="testimonials-slider"]');

    const swiper = new Swiper(sliderElement, {
        modules: [ Navigation, EffectFade ],
        // slidesPerView: 1,
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            slideChange: function(){
                console.log('slide change')
                const container = $qs('[data-name="testimonials"]');
                let sliderCounterParent = $qs('[data-target="slider-count"]', container);
                let { countStarted } = sliderCounterParent.dataset;
                let currentSlide = this.previousRealIndex;
                let currentCountNumber = $qs(`[data-count="${currentSlide}"]`, container);
                let nextCountNumber = $qs(`[data-count="${this.realIndex}"]`, container);
console.info(nextCountNumber);

                if (currentSlide == this.realIndex) return;
                
                const timeline = gsap.timeline({});

                timeline
                    .addLabel('start')
                    .to(currentCountNumber, {
                        y: '-100%',
                        duration: 0.3,
                        ease: 'power3.inOut'
                    }, 'start')
                    .to(nextCountNumber, {
                        y: '0%',
                        duration: 0.3,
                        ease: 'power3.inOut'
                    }, 'start');

                    timeline.set(currentCountNumber, {
                        y: '100%',
                    });
            },
        },
    });
}

function moveCursor(e, customCursor, container) {
    const { pageX, pageY } = e;
    const containerDistanceFromTop = window.scrollY + container.getBoundingClientRect().top;
    const containerDistanceFromLeft = window.scrollX + container.getBoundingClientRect().left;
    
    gsap.to(customCursor, {
      x: pageX - containerDistanceFromLeft,
      y: pageY - containerDistanceFromTop,
      duration: 0.3
    });
    
    // updateCustomCursorStyle(e, customCursor);
}

function customCursor() {
    const customCursorContainers = $qsa('[data-cursor="true"]');

    if (!customCursorContainers) return;
    if (!media.xl) return;

    [...customCursorContainers].forEach(container => {
        const customCursor = $qs('[data-target="custom-cursor"]', container);

        container.addEventListener('mousemove', e => moveCursor(e, customCursor, container));
        container.addEventListener('wheel', e => moveCursor(e, customCursor, container));
        container.addEventListener('mouseenter', e => {
            customCursor.dataset.state = 'active';
        });
        container.addEventListener('mouseleave', e => {
            customCursor.dataset.state = 'inactive';
        });
    });
}

function init() {
    loadingAnimation();
    Lottie();
    scrollText();
    textReveal();
    videoPopup();
    principlesSlider();
    testimonialsSlider();
    customCursor();
}

init();