import { $qs, $qsa } from '../utils/QuerySelector';
import bodymovin from 'lottie-web';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);



function createLottie(fileName, containerId) {
    const animation = bodymovin.loadAnimation({
        // animationData: { /* ... */ },
        container: $qs(`${containerId}`),
        path: `./assets/lottie/${fileName}.json`,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        name: fileName,
    });

    window.addEventListener('load', () => {
        animation.play();
    });
    
    // ScrollTrigger.create({
    //     trigger: $qs(`${containerId}`),
    //     start: '75% bottom',
    //     onEnter: () => {
    //         animation.play();
    //     }
    // });
    return animation;
}

function Lottie() {
    // const bannerLottie = createLottie('artu-twk-logo-animation', '#banner-lottie');    
    const bannerLottie = createLottie('TWK-logo-animation', '#banner-lottie');    
}

export default Lottie;