/* 
* A class to simplify JS media queries.
* using a size will return true if the screen size is below that size, and false if it is above
*/

class Media {
    constructor() {
        this.sizes = {
            xs: 556,
            sm: 696,
            md: 824,
            lg: 1080,
            xl: 1280,
            '2xl': 1546
        };

        // init
        this.setSizes();
    }

    setSizes() {
        Object.entries(this.sizes).forEach(([key, value]) => {
            this[key] = window.matchMedia(`(min-width: ${value}px)`).matches;
        });
    }

    max(size) {
        return size ? window.matchMedia(`(max-width: ${size}px)`).matches : console.error('no custom size supplied');
    }

    min(size) {
        return size ? window.matchMedia(`(min-width: ${size}px)`).matches : console.error('no custom size supplied');
    }
}

export const media = new Media;